namespace $ {
	const $texspb_context_dto = $mol_data_record( {
		current_user: $texspb_user_dto
	} )

	export class $texspb_context extends $mol_object2 {

		@$mol_mem
		static current() {
			return this.create()
		}

		@$mol_mem
		json() {
			const creds = this.$.$texspb_transport.credentials()

			if( !creds || !creds.password ) return null

			const resp = this.$.$texspb_transport.load( 'context' )

			// TODO Убрать
			if( !resp.ok ) {
				console.log( resp )
				throw new Error( resp.error?.message ?? 'Ошибка API' )
			}

			const context = $texspb_context_dto( resp.result )

			this.$.$texspb_user.byId( context.current_user.id ).json( context.current_user )

			return context
		}

		@$mol_mem
		user_id() {
			return this.json()?.current_user.id
		}

	}

}
