$texspb_wms_app $mol_book2
	auto /
		<= audio_room_status
	-
	- \Запретить ввод излишков
	prohibit_surplus true
	- \Запретить ручное изменение кол-ва
	prohibit_manual_quantity true
	- \Блокировать ввод до подтверждения ошибки
	force_error_confirm true
	-
	group_name_map *
		place \Место
		kgt \КГТ
		product \Товар
	-
	document_type_name_map *
		customerorder \Заказ покупателя
	-
	check_item_status_map *
		empty \—
		partial \<
		match \=
		surplus \>
	-
	- code_type_map *
		control \control
		item \item
		unknown \unknown
		error \error
	-
	code_info_type_map *
		ok \ok
		warn \warn
		error \error
		none \
	-
	check_list_empty_title_map *
		no_order \
			\Для начала проверки отсканируйте штрихкод с номером заказа
		check_done \
			\Количество товара сошлось.
			\Для продолжения проверки отсканируйте штрихкод с номером следующего заказа.
		empty_search \
			\Соответствий не найдено
	-
	plugins /
		<= Theme $mol_theme_auto
	Placeholder null
	pages /
		<= Gap*left
		<= Main_page
		<= Gap*right
	-
	Sign_in_form $texspb_sign_in
		logo_uri \https://texspb.ru/wa-data/public/site/svg/logo.svg
	-
	Main_page $texspb_wms_page
		title <= check_mode_name \Проверка документа
		minimal_width 320
		title_content <= main_page_title_content /
			<= Check_mode $mol_view sub /
				<= check_mode_name
			<= Target_doc $mol_view sub /
				<= doc_current_name_full null
		tools /
			<= Options_menu $texspb_wms_app_menu
				hint \Настройки
				menu_items <= options_menu_items
		toolbar_content /
			- TODO \Нужно ли использовать тут $mol_search (нужна очистка поля по кнопке)
			<= Code $mol_search
				hint <= code_hint \Отсканируйте штрихкод ...
				enabled <= code_input_enabled true
				submit? <=> code_submit? null
				clear? <=> code_clear? null
				query? <=> code_query? \
			- \Информация или ошибка по последнему введенному коду
			<= Code_info $texspb_wms_app_code_info
				Anchor <= Code -
				showed? <=> code_info_showed? false
				align \bottom_center
				message_text <= code_info_message null
				submit_type <= code_info_type \ok
				message_close_click? <=> code_info_close_click? null
		body <= main_page_body /
			<= Items_filter_mode_switch $mol_switch
				value? <=> filter_mode? \discrepancy
				- TODO Название не удачное
				options <= filter_mode_map *
					all \Все
					discrepancy \Расхождения
					search \Поиск
			<= Search $mol_search
				hint <= search_hint @ \Поиск в списке
				query? <=> search_query? \
			<= Check_items_list $mol_list
				rows <= check_items_rows /$mol_view
				Empty <= Check_list_empty $texspb_wms_app_list_empty_caption
					title <= check_list_empty_title \Список пуст
					success <= success_label false
		foot <= main_page_footer /
			<= User_current $texspb_user_current_menu
			- <= Sync_status $mol_button_minor
				- TODO У кнопки нет хинта..
				hint \Синхронихация пока не реализована
				enabled false
				click?event <=> fail?event null
				sub /
					<= Sync_status_icon $mol_icon_sync
	- \Меню
	options_menu_items /
		- \Отмена проверки
		<= Menu_item_cancel $mol_button_minor
			enabled <= menu_item_cancel_enabled false
			event_click?val <=> menu_item_cancel_click?val null
			sub /
				<= menu_item_cancel_icon $mol_icon_cancel
				<= menu_item_cancel_label \Отменить проверку
		- \Настройки
		<= Menu_item_settings $mol_link
			arg * settings \
			event_click?val <=> menu_item_settings_click?val null
			sub /
				<= menu_item_settings_icon $mol_icon_settings
				<= menu_item_settings_label \Настройки
	-
	Settings $texspb_wms_settings
	-
	Check_group* $mol_row sub /
		<= Check_group_caption* $mol_view sub /
			<= check_group_name* \Группа
		<= Check_group_total* $mol_view sub /
			<= check_group_total* \
	-
	Check_item_row* $mol_link
		arg <= check_item_arg* *
		hint <= check_item_hint* \Подсказка
		sub / <= Check_item_row_content* $mol_list rows <= check_item_row_content* /
			- \Наименование позиции
			<= Check_item_top* $mol_row sub /
				<= Check_item_title* $mol_dimmer
					needle <= search_query
					haystack <= check_item_title* \Строка
			- \Проверка
			<= Check_item_bottom* $mol_row sub /
				- \Кол-во (проверено)
				<= check_item_quantity_control* null
				- \Статус
				<= Check_item_status* $texspb_wms_app_item_status
					check_status <= check_item_status* null
					value <= check_item_status_title* null
				- \Кол-во
				<= Doc_item_quantity* $texspb_label
					value <= doc_item_quantity* 0
	check_item_quantity*? null
	Check_item_quantity_editable* $mol_number
		- TIPS оставляем по умолчанию null, чтобы позволить полю быть в пустом стоянии
		value? <=> check_item_quantity*? -
		dec_enabled <= check_item_quantity_dec_enabled* false
	Check_item_quantity_protected* $texspb_label
		value <= check_item_quantity* -
	- TODO \Нужен ли разделитель между группами?
	Spacer* $mol_view
	-
	beep_timeout_before_sec .200
	Audio_room $mol_audio_room
		status? => audio_room_status?
		active? => audio_room_active?
		input /
			<= Beep_position $mol_audio_melody
				start => beep_position_play
				note_length .150
				note_off_part .1
				notes? <=> beep_position_notes? \a2 _/3 e3/3
				instrument* <= Beep_position_vibe* $mol_audio_vibe
					shape_default <= beep_position_shape null
			<= Beep_start $mol_audio_melody
				start => beep_start_play
				note_length .200
				note_off_part .1
				notes? <=> beep_start_notes? \c1/2 e g
				instrument* <= Beep_start_vibe* $mol_audio_vibe
					shape_default <= beep_start_shape null
			<= Beep_fail $mol_audio_melody
				start => beep_fail_play
				note_length .600
				note_off_part .1
				notes? <=> beep_fail_notes? \c#0/3 d-1/2
				instrument* <= Beep_fail_vibe* $mol_audio_vibe
					shape_default <= beep_fail_shape null
- TIPS \Нужно наследоваться для указания атрибутов https://t.me/mam_mol/112275
$texspb_wms_app_item_status $texspb_label
	attr *
		^
		check_status <= check_status null

$texspb_wms_app_list_empty_caption $mol_paragraph
	attr *
		^
		success <= success false

$texspb_wms_app_menu $mol_pick
	event *
		^
		click? <=> click_event? null
	trigger_content <= menu_trigger_content /
		<= menu_trigger <= Menu_trigger $mol_icon_dots_vertical
	bubble_content <= menu_items_bubble_content /
		<= Options_content $mol_list
			rows <= menu_items /$mol_view

$texspb_wms_app_code_info $mol_pop
	attr *
		^
		tabindex -1
	bubble_content /
		<= Content $texspb_wms_app_code_submit_result_content
			submit_type <= submit_type null
			minimal_width 150
			sub / 
				<= Message $texspb_label
					value <= message_text null
				<= close_trigger null
	-
	Close_ok $mol_button_minor
		event_click? <=> message_close_click? null
		sub / <= Close_icon $mol_icon_close
	Close_error $mol_button_major
		title \Продолжить
		event_click? <=> message_close_click? null

$texspb_wms_app_code_submit_result_content $mol_row
	submit_type null
	attr *
		^
		- submit_type <= submit_type null
		mol_theme <= code_info_theme \$texspb_theme_ok
