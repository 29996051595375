namespace $ {
	const Barcode = $mol_data_record( {
		barcode: $mol_data_string,
		quantity: $mol_data_number
	} )
	
	const Position = $mol_data_record( {
		type: $mol_data_string,
		group: $mol_data_string,
		id: $mol_data_string,
		assortment_id: $mol_data_optional( $mol_data_string ),
		name: $mol_data_string,
		code: $mol_data_optional( $mol_data_string ),
		quantity: $mol_data_number,
		barcodes: $mol_data_array( Barcode ),
	} )

	const Document = $mol_data_record( {
		id: $mol_data_string,
		type: $mol_data_string,
		name: $mol_data_string,
		positions: $mol_data_array( Position ),
		documentContext: $mol_data_record( {
			orderType: $mol_data_string,
			moveId: $mol_data_variant(
				$mol_data_string,
				$mol_data_const( null )
			)
		} )
	} )

	const ResponseOk = $mol_data_record( {
		ok: $mol_data_const( true as true ),
		result: Document,
	} )

	const ResponseError = $mol_data_record( {
		ok: $mol_data_const( false as false ),
		error: $mol_data_record( {
			code: $mol_data_string,
			message: $mol_data_string,
			stack: $mol_data_optional( $mol_data_array( $mol_data_string ) )
		} )
	} )

	const Response = $mol_data_variant( ResponseOk, ResponseError )

	const CheckResponseOk = $mol_data_record( {
		ok: $mol_data_const( true as true ),
		result: $mol_data_record( {} ),
	} )

	const CheckResponse = $mol_data_variant( CheckResponseOk, ResponseError )


	export type $texspb_document_dto = typeof Response.Value

	export class $texspb_document_error extends Error {
		constructor( public error_dto: typeof ResponseError.Value ) {
			super( error_dto.error.message )
		}
	}

	export class $texspb_document extends $mol_object2 {
		static type() {
			return "customerorder"
		}

		@$mol_mem_key
		static item( name: string ) {
			return this.create( ( instance ) => {
				instance.name = $mol_const( $mol_data_string( name ) )
			} )
		}

		document_context() {
			return this.data().documentContext
		}

		@$mol_mem
		id() {
			return this.data().id
		}

		@$mol_mem
		name() {
			return this.data().name
		}

		@$mol_mem
		data() {
			const type = ( this.constructor as typeof $texspb_document ).type()
			const name = this.name()

			const response = $texspb_transport.load( `${ type }/name/${ name }` )

			const resp = Response( response )

			if( !resp.ok ) {
				throw new $texspb_document_error( resp )
			}

			return resp.result
		}

		@$mol_mem
		checked( next?: boolean ) {
			if( next === undefined ) return false

			if( next !== true ) {
				throw new Error( 'Нельзя отменить факт проверки заказа' )
			}

			const type = ( this.constructor as typeof $texspb_document ).type()

			const response = $texspb_transport.save(
				'put', 
				`${ type }/${ this.id() }/checked`, 
				this.document_context()
			)

			const resp = CheckResponse( response )

			if( !resp.ok ) {
				throw new $texspb_document_error( resp )
			}

			return true
		}
	}
}
