namespace $ {

	export class $texspb_sign extends $mol_object2 {

		@$mol_action
		static auth( creds: $texspb_transport_credentials ) {
			this.$.$texspb_transport.credentials( creds )
		}

		/** Не авторизован `true` когда был не успешный ввод пароля */
		@$mol_mem
		static unauthorized( next?: boolean ) {
			return next !== undefined ? next : false
		}

		@$mol_mem
		static signed() {
			try {

				const signed = Boolean( this.$.$texspb_context.current().user_id() )

				this.unauthorized( false )

				return signed

			} catch( err ) {
				if( this.$.$mol_fail_catch( err ) && err instanceof Error ) {
					// https://cloud.yandex.ru/docs/api-gateway/concepts/extensions/function-authorizer#errors
					switch( err.message ) {
						// TODO Нужно выбрасывать тип ошибки из транспорта
						case 'HTTP Error 401':
						case 'HTTP Error 403': {
							this.unauthorized( true )
							this.drop()
							return false
						}

						default:
							throw err
					}
				}
			}
		}

		static credentials() {
			return this.$.$texspb_transport.credentials()
		}

		static drop() {
			const creds = this.$.$texspb_transport.credentials()

			if( !creds ) return

			this.$.$texspb_transport.credentials( {
				...creds,
				password: ''
			} )
		}

	}

}
