namespace $ {

	export interface $texspb_entity_query {
		path: string
		query?: {}
	}

	/* const $texspb_entity_list_dto = $mol_data_record( {
		size: $mol_data_number,
		limit: $mol_data_number,
		items: $mol_data_array( $mol_data_pipe( ( it: any ) => it ) )
	} ) */

	export const $texspb_entity_instance_init =
		<T extends $texspb_entity<any>>( id: string ) =>
			( instance: T ) => {
				instance.id = $mol_const( $mol_data_string( id ) )
			}

	export class $texspb_entity<Json> extends $mol_object2 {

		fromDTO( input: any ): Json {
			return input
		}


		id() {
			return ''
		}

		@$mol_mem
		json( next?: Json ) {
			if( next ) return this.fromDTO( next )

			const resp = $texspb_transport.load( this.id() )

			const resp_validated = this.fromDTO( resp )

			return resp_validated
		}

	}

}
