$texspb_sign_in $mol_view
	title \Авторизация
	message *
		required \Необходимо заполнить
		no_access \Неверный логин или пароль
	sub /
		<= Logo $mol_image
			title \Логотип
			uri <= logo_uri \
		<= Form $mol_form
			submit? <=> sign_in_submit? null
			form_fields /
				<= Login_field $mol_form_field
					name <= login_label \Логин
					bid <= login_bid \
					control <= Login_control $mol_string
						value? <=> login? \
					event *
						input? <=> form_input*login? null
				<= Password_field $mol_form_field
					name <= password_label \Пароль
					bid <= password_bid \
					control <= Pass_control $mol_string
						value? <=> password? \
						type \password
					event *
						input? <=> form_input*password? null
			buttons /
				<= Sign_in $mol_button_major
					title \Войти
					click? <=> sign_in_submit? null
					enabled <= sign_in_allowed true
				<= Result $mol_status
					message <= sign_in_result? \

