namespace $.$$ {

	export class $texspb_user_current_menu extends $.$texspb_user_current_menu{

		override user() {
			return this.$.$texspb_user.current()!
		}

		override sign_out_click() {
			this.$.$texspb_sign.drop()
		}

		override user_image_uri() {
			return this.user_image_url() ?? ''
		}

		avatar() {
			if( this.user_image_url() ) return this.User_image()
			if( this.avatar_id() ) return this.User_avatar()
			return this.User_icon()
		}
	}

}
