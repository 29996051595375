namespace $.$$ {

	$mol_style_define( $texspb_user_current_menu, {
		User_image: {
			
			width: $mol_style_unit.em( 1.5 ),
			
			border: {
				radius: $mol_style_unit.px( 100 ),
				style: 'solid',
				color: $mol_theme.back
			},

			background: {
				color: $mol_theme.back
			}
		},
	} )

}
