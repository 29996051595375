$texspb_wms_settings $mol_page
	title \Настройки
	attr *
		^
		mol_theme \$mol_theme_special
	tools /
		<= Lights $mol_lights_toggle
		<= Settings_close $mol_link
			arg * settings null
			sub /
				<= Settings_close_icon $mol_icon_close
	body /
		<= Rows_list $mol_list rows /
			<= Revision $mol_row sub /
				<= Revision_label $texspb_wms_settings_revision
					avatar_id <= revision_id \
	-
$texspb_wms_settings_revision $mol_labeler
	attr *
		^
		title <= avatar_id \
	title \Ревизия приложения
	content /
		<= Revision_avatar $mol_avatar
			id <= avatar_id
		<= Revision_label $texspb_label
			value <= avatar_id
