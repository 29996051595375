namespace $.$$ {
	$mol_style_define($texspb_label, {
		// border: undefined, // TODO Вызывает ошибку. Бага?
		whiteSpace: "pre-line",
		overflow: "hidden",
		textOverflow: "ellipsis",
		padding: $mol_gap.text,
		textAlign: "left",
		position: "relative",
		flex: {
			// grow: 1,
			shrink: 1,
			basis: "auto",
		},
		/* background: {
			color: $mol_theme.field,
		}, */
	});
}
