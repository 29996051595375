namespace $.$$ {
	$mol_style_define($texspb_wms_page, {
		Page_head: {
			flex: {
				grow: 1,
			},
		},

		Toolbar: {
			display: "flex",
			// background: {
			// 	color: $mol_style_func.vary("--mol_theme_back"),
			// },
		},
	});
}
