namespace $.$$ {

	export class $texspb_sign_in extends $.$texspb_sign_in {

		override sign_in_submit() {
			this.$.$texspb_sign.auth( {
				login: this.login() ?? '',
				password: this.password(),
			} )
		}

		override sign_in_allowed(): boolean {
			return !!( this.login() && this.password() )
		}

		@$mol_mem
		override sign_in_result() {
			if( this.$.$texspb_sign.unauthorized() ) {
				return this.message().no_access
			}

			return ''
		}

		@$mol_mem
		override login( next?: string ) {
			if( next === undefined ) {
				return this.$.$texspb_sign.credentials()?.login ?? ''
			}

			return next
		}

		override login_bid(): string {
			return ( this.dirty( 'login' ) || this.dirty( 'password' ) ) && !this.login() ? this.message().required : ''
		}

		@$mol_mem
		override password_bid(): string {
			return this.dirty( 'password' ) && !this.password() ? this.message().required : ''
		}

		override form_input( id: 'login' | 'password', next?: any ) {
			this.dirty( id, true )
		}

		/** Изменялись ли поля в форме */
		@$mol_mem_key
		dirty( id: 'login' | 'password', next?: boolean ) {
			/** Нужно сбросить при изменени статуса авторизации */
			this.$.$texspb_sign.signed()

			if( next === undefined ) return false

			return true
		}

	}

}
