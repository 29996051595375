$texspb_user_current_menu $mol_pick
	user $texspb_user
		image_url => user_image_url
		uid => avatar_id
		short_fio => user_name
	-
	event *
		^
		click? <=> click_event? null
	trigger_content /
		<= avatar null
		<= User_name_content $mol_view sub /
			<= user_name
	bubble_content /
		<= Menu_content $mol_list rows /
			<= Menu_item_sign_out $mol_button_minor
				event_click? <=> sign_out_click? null
				sub /
					<= Sign_out_icon $mol_icon_logout
					<= sign_out_text \Выйти
	-
	User_icon $mol_icon_account
	User_avatar $mol_avatar
		id <= avatar_id
	User_image $mol_image
		title <= user_name
		uri <= user_image_uri \
