namespace $.$$ {
	const { rem, per } = $mol_style_unit

	$mol_style_define( $texspb_wms_app, {
		Main_page: {
			flex: {
				grow: 0,
				basis: rem( 50 ),
			},

			Title: {
				flex: {
					wrap: "wrap",
				},
			},
		},

		Target_doc: {
			font: {
				weight: "bolder",
			},
		},

		Settings: {
			flex: {
				grow: 0,
				basis: rem( 20 ),
			},
		},

		Items_filter_mode_switch: {
			flex: "none",
			alignSelf: "center",
		},

		Search: {
			flex: "none",
			alignSelf: "stretch",
			padding: $mol_gap.text,
		},

		Check_list_empty: {
			"@": {
				success: {
					true: {
						backgroundColor: "green",
						color: "white",
						border: { radius: $mol_gap.round },
					},
				},
			},
			justifyContent: "center",
			textAlign: "center",
			padding: $mol_gap.block,
			margin: $mol_gap.block,
		},

		Check_group: {
			background: {
				color: $mol_theme.card,
			},
		},

		Check_group_caption: {
			font: {
				weight: "bolder",
			},
		},

		Check_group_total: {
			color: $mol_colors.gray,
		},

		Check_item_row: {
			padding: {
				top: 0,
				bottom: 0,
			},
		},

		Check_item_top: {
			padding: {
				bottom: $mol_gap.space,
			},
		},

		Check_item_bottom: {
			padding: {
				top: 0,
			},
			// gap: rem(0),
		},

		Check_item_status: {
			"@": {
				check_status: {
					empty: {
						background: { color: "lightgrey" },
					},
					partial: {
						background: { color: "blue" },
					},
					match: {
						background: { color: "green" },
					},
					surplus: {
						background: { color: "orange" },
					},
				},
			},
			color: "white",
			border: { radius: $mol_gap.round },
			height: rem( 1 ),
			width: rem( 2 ),
			justifyContent: "center",
			fontWeight: "bolder",
		},

		Check_item_quantity_editable: {
			$mol_string: {
				width: rem( 4 ),
			},
		},

		Check_item_quantity_protected: {
			width: rem( 3.5 ),
		},

		Spacer: {
			height: $mol_gap.block,
		},

		Code_info: {
			flex: {
				grow: 1,
			},

			Bubble: {
				maxWidth: per( 80 ),
				zIndex: $mol_layer.float,
			},
		},

		Menu_item_settings: {
			gap: $mol_gap.space,
		},

		Menu_item_cancel: {
			color: "red",
		},
	} )

	$mol_style_define( $texspb_wms_app_code_submit_result_content, {
		"@": {
			mol_theme: {
				'$texspb_theme_error': {
					flex: {
						direction: "column",
					},
				},
			},
		},
		flex: {
			wrap: "nowrap",
		},
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		gap: 0,
	} )
}
