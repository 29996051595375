namespace $.$$ {
	$mol_style_define( $texspb_sign_in, {
		
		display: 'flex',

		flex: {
			grow: 1,
			shrink: 1,
			basis: 'auto'
		},

		justifyContent: 'center',

		alignItems: 'center',

		flexDirection: 'column',

		Logo: {
			
			maxWidth: $mol_style_unit.rem( 17 ),
			
			padding: {
				bottom: $mol_style_unit.rem( 1 ),
			}

		},

		Form: {
			maxWidth: $mol_style_unit.rem( 17 )
		},

		Result: {
			display: 'flex',
			padding: 0,
			flex: {
				grow: 1
			},
			justifyContent: 'center'
		},

		Sign_in: {

			flex: {
				grow: 1
			},

			justifyContent: 'center'

		}

	} )
}
