namespace $.$$ {

	export class $texspb_wms_settings extends $.$texspb_wms_settings {

		override revision_id() {
			return $texspb_revision
		}

	}

}
