namespace $ {

	export const $texspb_user_dto = $mol_data_record( {
		id: $mol_data_string,
		uid: $mol_data_string,
		short_fio: $mol_data_string,
		full_name: $mol_data_string,
		image_url: $mol_data_optional( $mol_data_string )
	} )

	export class $texspb_user extends $texspb_entity<typeof $texspb_user_dto.Value> {
		
		@$mol_mem_key
		static byId( id: string ) {
			return this.create( $texspb_entity_instance_init( id ) )
		}

		@$mol_mem
		static current() {
			const user_id = this.$.$texspb_context.current().user_id()
			return user_id ? this.byId( user_id ) : null
		}
		
		fromDTO( dto: any ) {
			return $texspb_user_dto( dto )
		}

		uid() {
			return this.json().uid
		}

		short_fio() {
			return this.json().short_fio
		}

		image_url() {
			return this.json().image_url
		}

	}

}
