namespace $.$$ {
	const { rem } = $mol_style_unit

	$mol_style_define( $texspb_wms_settings, {

		Rows_list: {
			'$mol_row': {
				padding: 0
			}
		}

	} )

	$mol_style_define( $texspb_wms_settings_revision, {

		Revision_label: {
			padding: {
				top: 0
			}
		}

	} )

}
