namespace $.$$ {
	type $texspb_wms_app_filter_modes = keyof ReturnType<
		$texspb_wms_app[ "filter_mode_map" ]
	>

	type $texspb_wms_app_check_item_status = keyof ReturnType<
		$texspb_wms_app[ "check_item_status_map" ]
	>

	type $texspb_wms_doc_type = ReturnType<$texspb_wms_app[ "doc" ]>

	type $texspb_wms_code =
		| {
			type: "control"
			value: string
			control_code: string
		}
		| {
			type: "document"
			value: string
			doc_name: string
		}
		| {
			type: "item"
			value: string
			item_id: string
			quantity: number
		}
		| {
			type: "unknown"
			value: string
		}
		| {
			type: "error"
			value: string
			message: string
		}

	type $texspb_wms_code_issue = "unknown" | "surplus" | "error"

	export class $texspb_wms_app extends $.$texspb_wms_app {
		// Тип не выводится из view.tree
		override beep_start_shape(): $mol_audio_vibe_shape {
			return 'square'
		}

		override beep_position_shape(): $mol_audio_vibe_shape {
			return 'square'
		}

		override beep_fail_shape(): $mol_audio_vibe_shape {
			return 'sawtooth'
		}

		/** Отменить текущую проверку */
		check_cancel() {
			this.doc_current( null )
			this.check_item_active( null )
			this.code_last( null )
		}

		@$mol_mem_key
		override check_group_name( id: string ): string {
			return (
				this.group_name_map() as Record<string, string>
			)[ id ] ?? super.check_group_name( id )
		}

		@$mol_mem
		check_group_names() {
			return $texspb_tools.dict_keys_enum( this.group_name_map() )
		}

		@$mol_mem_key
		override check_group_total( id: any ): string {
			const items =
				this.doc_current()
					?.data()
					?.positions.filter( ( it ) => it.group === id ) ?? []

			const total = items.reduce( ( res, it ) => res + it.quantity, 0 )

			const checked = items.reduce(
				( res, it ) => res + ( this.check_item_quantity( it.id ) ?? 0 ),
				0
			)

			return `${ checked } / ${ total }`
		}

		/** Текущая активная позиция в списке */
		@$mol_mem
		check_item_active( id?: string | null ) {
			return this.$.$mol_state_arg.value( "item", id )
		}

		@$mol_action
		check_item_add( id: string, quantity: number ) {
			this.check_items_ids(
				[ ...new Set( [ ...this.check_items_ids(), id ] ) ]
			)

			this.check_item_quantity(
				id,
				( this.check_item_quantity( id ) ?? 0 ) + quantity
			)
		}

		@$mol_mem_key
		override check_item_arg( id: string ) {
			return {
				item: id,
			}
		}

		/**
		 * Кол-во проверенного товара
		 * 
		 * @param id Комплексный идентификатор `[id документа]/[id товара]`
		 * @param next 
		 */
		@$mol_mem_key
		override check_item_quantity( id: string, next?: number | null ) {
			if( next !== undefined ) {
				if( next === null ) return null
				if( Number.isNaN( next ) ) return 0
				if( next < 0 ) return 0
				return next
			}
			return 0
		}

		check_item_quantity_reset() {

		}

		@$mol_mem_key
		override check_item_quantity_control( id: string ) {
			return this.prohibit_manual_quantity()
				? this.Check_item_quantity_protected( id )
				: this.Check_item_quantity_editable( id )
		}

		override check_item_quantity_dec_enabled( id: any ): boolean {
			return ( this.check_item_quantity( id ) ?? 0 ) > 0
		}

		@$mol_mem_key
		override check_item_status(
			id: string
		): $texspb_wms_app_check_item_status | undefined {
			const quantity = this.doc_item_quantity( id )
			const checked = this.check_item_quantity( id ) ?? 0

			const subst = quantity - checked

			if( subst === quantity ) return "empty"
			if( subst === 0 ) return "match"
			else if( subst > 0 ) return "partial"
			else return "surplus"
		}

		override check_item_status_title( id: string ) {
			const status = this.check_item_status( id )

			return status ? this.check_item_status_map()[ status ] : null
		}

		/** Все статусы проерки позиции */
		@$mol_mem
		check_item_statuses() {
			return $texspb_tools.dict_keys_enum( this.check_item_status_map() )
		}

		@$mol_mem_key
		override check_item_title( id: string ) {
			const item = this.doc_item( id )
			// const code = item.code
			const name = item.name
			const quantity = item.quantity
			const checked = this.check_item_quantity( item.id ) ?? 0

			// TODO Кастомная формула для генерации наименования строки
			return (
				name +
				( item.group !== this.check_group_names().place
					? ` • ${ checked } из ${ quantity }`
					: "" )
			)
		}

		/** Идентификаторы проверенных позиций */
		@$mol_mem
		check_items_ids( next?: string[] ) {
			if( next !== undefined ) return next
			return []
		}

		@$mol_mem
		override check_items_rows() {
			const rows_by_type = new Map<string, any[]>()

			const doc = this.doc_current()

			if( !doc ) return []

			let items

			switch( this.filter_mode() ) {
				case this.filter_modes().search:
					items = this.doc_current_filtered_items()
					break

				case this.filter_modes().discrepancy:
					items = this.doc_current_discrepancy_items()
					break

				default:
					items = this.doc_current_items()
					break
			}

			for( const item of items ) {
				const item_group = item.group

				const group_rows = rows_by_type.get( item_group ) ?? []

				if( group_rows.length === 0 ) {
					group_rows.push(
						this.Spacer( item_group ),
						this.Check_group( item_group )
					)
				}
				group_rows.push( this.Check_item_row( item.id ) )

				rows_by_type.set( item_group, group_rows )
			}

			const groups_codes = Object.keys( this.group_name_map() )

			const rows = groups_codes.flatMap(
				( group_code ) => rows_by_type.get( group_code ) ?? []
			)

			return rows
		}

		override check_list_empty_title() {
			// Заказ не выбран (список пуст)
			if( !this.doc_current() ) {
				// TODO Убрать в справочник
				return this.check_list_empty_title_map().no_order
			}

			// Заказ проверен (список "Расхождения" пуст)
			else if(
				this.doc_current_checked() &&
				this.filter_mode() === this.filter_modes().discrepancy
			) {
				return this.check_list_empty_title_map().check_done
			}

			// Фильтр не вернул результата
			else if(
				this.doc_current_filtered_items().length === 0 &&
				this.filter_mode() === this.filter_modes().search
			) {
				return this.check_list_empty_title_map().empty_search
			}

			// Прочие
			else {
				return super.check_list_empty_title()
			}
		}

		/** Очищает и фокусирует поля ввода ШК */
		override code_clear() {
			this.Code().query( "" )
			this.code_input_focused( true )
		}

		/** Нажание на кнопку закрытия popup'a с информацией о введенном ШК  */
		override code_info_close_click() {
			this.code_last( null )
			this.code_input_focus()
		}

		/** Информационное сообщение для последнего кода */
		@$mol_mem
		override code_info_message() {
			const code = this.code_last()

			if( !code ) return null

			switch( code.type ) {
				case "control": {
					throw new Error( "Не реализовано" )
				}

				case "document": {
					return `Проверка документа №${ this.doc_current()?.name() }`
				}

				case "item": {
					return (
						( this.code_last_issue() === "surplus" ? "Лишняя позиция ➜ " : "" ) +
						this.check_item_title( code.item_id )
					)
				}

				case "unknown": {
					return `Не удалось найти соответствий для кода "${ code.value }"`
				}

				case "error": {
					return `Ошибка при вводе кода "${ code.value }" - ${ code.message }`
				}

				default: {
					const never_code: never = code
					throw new Error( "Неизвестный тип кода" )
				}
			}
		}

		/** Активировано ли поле ввода ШК */
		@$mol_mem
		override code_input_enabled() {
			if( this.doc_loading() ) return false

			// Активно всегда если нет требования подтверждать ошибки
			if( !this.force_error_confirm() ) return true

			// Активно если не отображается информация по коду
			if( !this.code_info_showed() ) return true

			const issue = this.code_last_issue()

			if( !issue ) return true

			if( issue === "surplus" && !this.prohibit_surplus() ) {
				return true
			} else {
				return false
			}
		}

		/** Активность поля ввода ШК */
		@$mol_mem
		code_input_focused( next?: boolean ) {
			return this.Code().Query().focused( next )
		}

		@$mol_action
		code_input_focus() {
			// TODO Было бы не плохо точно понять почему не работает без таймаута
			new this.$.$mol_after_timeout( 100, () => {
				this.code_input_focused( true )
			} )
		}

		/**
		 * Последний введенный ШК.
		 * Устанавливается при сканировании кода.
		 */
		@$mol_mem
		code_last( next?: $texspb_wms_code | null ) {
			if( next !== undefined ) return next
			return null
		}

		/** Ошибка или замечание по последнему введенному коду */
		@$mol_mem
		code_last_issue( next?: $texspb_wms_code_issue | null ) {
			if( next !== undefined ) return next
			return null
		}

		/** Отображение информации о введенном ШК */
		@$mol_mem
		override code_info_showed() {
			const showed = !!this.code_last()

			// Можно сфокусировать кнопку для возобновления по Enter,
			// но тогда можно возобновить простым считыванием любого ШК,
			// и ошибку можно случайно сбросить.

			// Поэтому фокусируем ближайший контейнер с которого можно
			// переключиться на кнопку по нажатию Tab

			if( showed && this.code_info_type() === "error" ) {
				new this.$.$mol_after_frame( () => {
					this.Code_info().Bubble().focused( true )
				} )
			}

			return showed
		}

		/** Тип всплывающего уведомления */
		@$mol_mem
		override code_info_type() {
			const { ok, warn, error } = this.code_info_type_map()

			const issue = this.code_last_issue()

			if( !issue ) return ok

			if( issue === "surplus" ) {
				return this.prohibit_surplus() ? error : warn
			} else {
				return error
			}
		}

		/**
		 * Обработка введенного кода
		 */
		code_scan( code: $texspb_wms_code ) {
			this.code_last( code )

			switch( code.type ) {
				// Ошибка
				case "error": {
					new this.$.$mol_after_timeout(
						this.beep_timeout_before_sec() * 1000, 
						() => this.beep_fail_play()
					)

					this.code_last_issue( "error" )

					break
				}

				// Неизвестный код
				case "unknown": {
					new this.$.$mol_after_timeout(
						this.beep_timeout_before_sec() * 1000, 
						() => this.beep_fail_play()
					)

					this.code_last_issue( "unknown" )

					break
				}

				// Коммандный код
				case "control": {
					throw new Error( "Коммандные коды не реализованы" )
					// break;
				}

				case "document": {
					new this.$.$mol_after_timeout(
						this.beep_timeout_before_sec() * 1000, 
						() => this.beep_start_play()
					)
					
					const doc = this.doc( code.doc_name )

					this.doc_current( doc )

					this.check_item_active( null )

					this.code_input_focus()

					break
				}

				// Код элемента
				case "item": {
					const item_id = code.item_id

					this.check_item_active( code.item_id )

					const expected_quantity = this.doc_item_quantity( item_id )

					const actual_quantity = this.check_item_quantity( item_id ) ?? 0

					/** Излишек? */
					const is_surplus = actual_quantity + code.quantity > expected_quantity

					if( is_surplus && this.prohibit_surplus() ) {
						this.code_last_issue( "surplus" )
						
						new this.$.$mol_after_timeout(
							this.beep_timeout_before_sec() * 1000, 
							() => this.beep_fail_play()
						)
					} else {
						this.code_last_issue( null )

						this.check_item_add( item_id, code.quantity )

						new this.$.$mol_after_timeout(
							this.beep_timeout_before_sec() * 1000, 
							() => this.beep_position_play()
						)
					}

					this.ensure_visible( this.Check_item_row( item_id ) )

					break
				}

				default: {
					const never_code: never = code
					throw new Error( "Неизвестный тип кода" )
				}
			}

			return code
		}

		/** Ввод ШК */
		@$mol_action
		override code_submit(): $texspb_wms_code | null {
			/** Значение в строке ввода ШК */
			const code_value = this.code_query()

			// Очистка значения в поле ввода ШК
			this.code_query( "" )

			if( code_value.trim() === "" ) return null

			if( this.doc_current() && !this.doc_current_checked() ) {
				const items = this.doc_items_by_code( code_value )

				// - Элемент есть в списке
				if( items.length ) {
					const item = items.find(
						it => this.check_item_status( it.position.id ) !== 'match'
					) ?? items[ items.length - 1 ]

					const item_id = item?.position.id
					const quantity = item.barcode.quantity

					return this.code_scan( {
						type: "item",
						item_id,
						value: code_value,
						quantity
					} )
				} else {
					return this.code_scan( {
						type: "unknown",
						value: code_value,
					} )
				}
			}

			// Текущий заказ не задан → скан наименования заказа
			else {
				try {
					this.doc_loading( true )

					const doc = this.doc( code_value )

					// FIXME Для триггера загрузки
					doc.id()

					const doc_name = doc.name()

					this.doc_loading( false )

					return this.code_scan( {
						type: "document",
						value: code_value,
						doc_name,
					} )
				} catch( err: any ) {
					if( $mol_fail_catch( err ) ) {
						this.doc_loading( false )

						if(
							err instanceof $texspb_document_error &&
							err.error_dto.error.code === "not_found"
						) {
							return this.code_scan( {
								type: "unknown",
								value: code_value,
							} )
						}

						return this.code_scan( {
							type: "error",
							value: code_value,
							message: err?.message,
						} )
					}
				}
			}

			return null
		}

		/** Документ по коду */
		@$mol_mem_key
		doc( name: string ) {
			return $texspb_document.item( name )
		}

		/** Текущий проверяемый документ */
		@$mol_mem
		doc_current( next?: $texspb_wms_doc_type | null ) {
			if( next !== undefined ) return next
			return null
		}

		override doc_current_name_full() {
			const doc = this.doc_current()

			if( doc ) {
				const name = (
					this.document_type_name_map() as Record<string, string>
				)[ doc.data().type ]

				return `${ name } №${ doc.name() }`
			} else {
				return null
			}
		}

		/** Товары в заказе сверены - расхождений нет */
		@$mol_mem
		doc_current_checked() {
			const doc = this.doc_current()

			if( !doc ) return false

			const checked = doc
				.data()
				.positions.every(
					( it ) => it.quantity === this.check_item_quantity( it.id )
				)

			if( checked === false ) return false

			if( doc.checked() === false ) {
				doc.checked( true )
			}

			new this.$.$mol_after_timeout(
				this.beep_timeout_before_sec() * 1000, 
				() => this.beep_start_play()
			)

			return true
		}

		/** Все позиции с расхождениями */
		@$mol_mem
		doc_current_discrepancy_items() {
			return this.doc_current_items().filter( ( it ) => {
				return it.quantity !== this.check_item_quantity( it.id )
			} )
		}

		/** Позиции в результате фильтрации */
		@$mol_mem
		doc_current_filtered_items() {
			const search = this.search_query().toLowerCase()
			const items = this.doc_current_items()

			return search
				? items.filter( ( it ) => {
					// TODO Возможно нужно перенести в модель
					// Для предотвращения очистки проверенного кол-ва во время фильтрации
					this.check_item_quantity( it.id )

					return (
						it.name.toLowerCase().includes( search ) ||
						it.barcodes.some(
							( b ) => b.barcode.toLowerCase().includes( search )
						)
					)
				} )
				: items
		}

		/** Позиции текущего документа */
		@$mol_mem
		doc_current_items() {
			return this.doc_current()?.data().positions ?? []
		}

		/** Позиция документа по идентификатору */
		@$mol_mem_key
		doc_item( id: string ) {
			return this.doc_current()!
				.data()
				.positions.find( ( it ) => it.id === id )!
		}

		/** Список ШК с позициями по введенному коду */
		@$mol_mem_key
		doc_items_by_code( code: string ) {
			let real_code = code

			const qr_start_match = code.substring( 0, 2 ) === '01'
			const qr_end_match = code.substring( 16, 18 ) === '21'

			// Код маркировки
			if( qr_start_match && qr_end_match && code.length > 18 ) {
				real_code = code.substring( 2, 16 )
			}

			const positions_with_barcode = this.doc_current()
				?.data()
				?.positions
				?.flatMap(
					( it ) => {
						const barcode = it.barcodes.find( b => b.barcode === real_code )

						if( barcode == null ) return []

						return {
							position: it,
							barcode
						}
					}
				) ?? []

			return positions_with_barcode
		}

		/**
		 * Кол-во товара в позиции документа
		 */
		@$mol_mem_key
		override doc_item_quantity( id: string ) {
			return this.doc_item( id ).quantity
		}

		// FIXME Отрефакторить и убрать. Некорректно построил реактивные связи.
		@$mol_mem
		doc_loading( next?: boolean ) {
			return next !== undefined ? next : false
		}

		/** Текущий режим фильтрации элементов */
		@$mol_mem
		override filter_mode( next?: $texspb_wms_app_filter_modes ) {
			return next !== undefined
				? next
				: ( super.filter_mode() as $texspb_wms_app_filter_modes )
		}

		/** Все режимы фильтрации строк */
		@$mol_mem
		filter_modes() {
			return $texspb_tools.dict_keys_enum( this.filter_mode_map() )
		}

		override main_page_body() {
			const hasItems = !!this.doc_current()?.data().positions.length

			const searchShowed =
				hasItems && this.filter_mode() === this.filter_modes().search

			return [
				hasItems ? this.Items_filter_mode_switch() : null,
				searchShowed ? this.Search() : null,
				this.Check_items_list(),
			].filter( ( it ) => it !== null )
		}

		override menu_item_cancel_click() {
			if( this.menu_item_cancel_enabled() ) {
				this.Options_menu().showed( false )
				this.check_cancel()
				this.code_input_focused( true )
			}
		}

		override menu_item_cancel_enabled(): boolean {
			return !!this.doc_current()
		}

		@$mol_mem
		override pages() {
			if( !this.$.$texspb_sign.signed() ) {
				return [ this.Sign_in_form() ]
			}

			return [ ...super.pages(), ...( this.settings() ? [ this.Settings() ] : [] ) ]
		}

		/** Флаг отображения страницы с настройками */
		@$mol_mem
		settings( next?: boolean ) {
			const str = next == undefined ? undefined : String( next )
			return this.$.$mol_state_arg.value( "settings", str ) !== null
		}

		override success_label(): boolean {
			const success =
				this.doc_current_checked() &&
				this.filter_mode() === this.filter_modes().discrepancy

			return success
		}
	}

	export class $texspb_wms_app_code_info extends $.$texspb_wms_app_code_info {
		@$mol_mem
		override close_trigger() {
			return this.submit_type() === "error"
				? this.Close_error()
				: this.Close_ok()
		}
	}

	export class $texspb_wms_app_code_submit_result_content extends $.$texspb_wms_app_code_submit_result_content {
		override code_info_theme() {
			return this.submit_type() === "error"
				? '$texspb_theme_error'
				: '$texspb_theme_ok'
		}
	}
}
