namespace $ {
	export interface $texspb_transport_credentials {
		login: string
		password: string
	}

	export class $texspb_transport extends $mol_object2 {
		@$mol_mem
		static api_base() {
			// TODO Как кастомизировать константы для тестовой и рабочей среды?

			const hostname = window.location.hostname

			if( hostname === 'localhost' || hostname === '127.0.0.1' ) {
				return "https://d5d7snhdu2ac202baiqs.apigw.yandexcloud.net/api"
			} else {
				return `${window.location.origin}/api`
			}

		}

		@$mol_mem
		static credentials( next?: $texspb_transport_credentials | null ) {
			return this.$.$mol_state_local.value( 'credentials', next )
		}

		@$mol_mem
		static headers() {
			const headers = {
				"Content-Type": "application/json",
			}

			const creds = this.credentials()
			if( !creds ) return headers

			const basic_auth_creds =
				$mol_base64_encode( `${ creds.login }:${ creds.password }` )

			return {
				...headers,
				'Authorization': `Basic ${ basic_auth_creds }`,
			}
		}

		static url( path: string, query?: Record<string, string> ) {
			const url = new URL( `${ this.api_base() }/${ path }` )

			if( query ) {
				for( const [ key, val ] of Object.entries( query ) )
					url.searchParams.set( key, val )
			}

			return url.toString()
		}

		@$mol_action
		static load( path: string, query?: Record<string, string> ): any {
			return this.$.$mol_fetch.json( this.url( path, query ), {
				headers: this.headers(),
			} )
		}

		@$mol_action
		static save(
			method: 'post' | 'put',
			path: string,
			body: object,
			query?: Record<string, string>
		): any {
			const uri = this.url( path, query )

			const res = this.$.$mol_fetch.json( uri, {
				method,
				headers: this.headers(),
				body: JSON.stringify( body ),
			} )

			return res
		}
	}
}
